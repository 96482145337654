import React, { useEffect, useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import * as queryString from "query-string";
import {
  AuthContext,
  fetchAuthenticatedContent,
} from "@parallelpublicworks/understory-auth";
import { SiteContext } from "../layouts/Main";
import { slugify, fetchAnonymousContent } from "../utils";
import mime from "mime-types";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import ComputerIcon from "@material-ui/icons/Computer";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    display: "block",
  },
  download: {
    maxWidth: 620,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center !important",
    padding: theme.spacing(6, 1),
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  icon: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    fill: theme.palette.grey["400"],
    marginTop: 0,
  },
  back: {
    display: "block",
    textAlign: "left",
    color: theme.palette.link.main,
    marginBottom: theme.spacing(-2),
    "& .MuiSvgIcon-root": {
      verticalAlign: "middle",
    },
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& > *": {
      width: "50%",
      "&.delete-btn": {
        width: "auto",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    "& > div": {
      padding: theme.spacing(0, 1),
      "& > *": {
        width: "100%",
        display: "block",
      },
    },
  },
}));

function Download({ location, data }) {
  const defaultErrorMsg = "An error occured while downloading your file";
  const query_string = queryString.parse(location.search);
  const media_uuid = query_string && query_string.media ? query_string.media : null;
  const public_endpoint = media_uuid ? `media/document/${media_uuid}/` : null;
  const private_endpoint = media_uuid ? `media/private_document/${media_uuid}/` : null;
  const any_endpoint = media_uuid ? `media/image/${media_uuid}/` : null;
  const files =
    data && data.files && data.files.edges && Array.isArray(data.files.edges)
      ? data.files.edges
      : [];
  const { drupalUrl, userData } = React.useContext(SiteContext);
  const [, dispatch] = React.useContext(AuthContext);
  const fileInput = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(defaultErrorMsg);
  const [authorDownloading, setAuthorDownloading] = useState(false);
  const [authorReplacing, setAuthorReplacing] = useState(false);
  const [authorDoneReplacing, setAuthorDoneReplacing] = useState(false);
  const [authorDeleting, setAuthorDeleting] = useState(false);
  const [authorDeletingMsg, setAuthorDeletingMsg] = useState(null);
  const [media, setMedia] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (media_uuid && media === null) {
        // media entity is returned no matter which media type is specified in the endpoint, hence the "any_endpoint"

        let response = await fetchAnonymousContent(
          `${drupalUrl}/jsonapi/${any_endpoint}`
        );
        if (!response || (response && !response.data?.attributes?.created)) {
          response = await fetchAuthenticatedContent(dispatch, any_endpoint, "get");
        }
        if (
          response.data &&
          response.data.id &&
          response.data.attributes &&
          response.data.attributes.created !== null
        ) {
          let m_data = response.data;
          let _private = m_data.type.includes("private");
          let mid = m_data.attributes.drupal_internal__mid;
          let download_token = _private
            ? await fetchAuthenticatedContent(
                dispatch,
                `limit_media_access/${mid}`,
                "get"
              )
            : null;
          if (
            _private &&
            download_token &&
            download_token.data &&
            typeof download_token.data === "string"
          ) {
            download_token = download_token.data;
          } else {
            download_token = null;
          }
          let title = m_data.attributes.name;
          let author =
            m_data.relationships &&
            m_data.relationships.uid &&
            m_data.relationships.uid.data
              ? m_data.relationships.uid.data.id
              : null;
          let file_field_key = `field_media_file${_private ? "_1" : ""}`;
          let file_uuid =
            m_data.relationships &&
            m_data.relationships[file_field_key] &&
            m_data.relationships[file_field_key].data
              ? m_data.relationships[file_field_key].data.id
              : null;
          let filemime = null;

          if (m_data.attributes.field_filemime) {
            filemime = m_data.attributes.field_filemime;
          } else {
            let file_meta = file_uuid
              ? files.find(({ node }) => node.id === file_uuid)
              : null;
            if (file_meta) {
              filemime = file_meta.node.filemime;
            }
          }
          let access_group = [];
          if (
            m_data.relationships &&
            m_data.relationships.field_access_group &&
            m_data.relationships.field_access_group.data &&
            Array.isArray(m_data.relationships.field_access_group.data)
          ) {
            access_group = m_data.relationships.field_access_group.data.map(
              ({ id }) => id
            );
          }

          const _media = {
            id: m_data.id,
            private: _private,
            author,
            mid,
            title,
            filemime,
            extension: filemime ? mime.extension(filemime) : null,
            access_group,
            download_token,
          };
          setMedia(_media);
        } else {
          setError(true);
          setErrorMsg("You don't have permission to download this file [1]");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (media) {
      let is_owner = userData ? userData.id === media.author : false;
      if (media.private && userData === false) {
        setErrorMsg("You don't have permission to download this file [2]");
        setError(true);
      } else {
        if (media.private && userData) {
          if (!is_owner) {
            if (media.access_group.length > 0) {
              const role_matches = media.access_group.filter((value) =>
                userData.roles.includes(value)
              );
              if (role_matches.length > 0) {
                downloadFile();
              } else {
                setErrorMsg("You don't have permission to download this file [3]");
                setError(true);
              }
            } else {
              downloadFile();
            }
          }
        } else if (!media.private) {
          if (userData === false) {
            downloadFile();
          } else {
            if (!is_owner) {
              downloadFile();
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, userData]);

  useEffect(() => {
    if (error === false) {
      navigate(location?.state?.referrer ? location.state.referrer : "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const downloadFile = async () => {
    try {
      if (typeof window !== `undefined`) {
        if (media && media.extension) {
          const download_token = media.private ? media.download_token : null;
          if (media.private && !download_token) {
            throw new Error("No download token");
          }
          let fetchUrl = `${drupalUrl}/media/${media.mid}`;
          if (download_token) fetchUrl += `?${download_token}`;
          fetch(fetchUrl, {
            method: "GET",
            headers: {
              "Content-Type": media.filemime,
            },
          })
            .then((response) => {
              return response.status !== 200 ? null : response;
            })
            .then((response) => (response ? response.blob() : null))
            .then((blob) => {
              try {
                if (blob) {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  let media_title =
                    media.title && media.title.includes(".")
                      ? media.title.split(".")[0]
                      : media.title;
                  link.setAttribute(
                    "download",
                    `${media_title ? slugify(media_title) : `file-${media.id}`}.${
                      media.extension
                    }`
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode.removeChild(link);
                  setError(false);
                } else {
                  throw new Error("Error transferring file");
                }
              } catch (error) {
                setErrorMsg(error.message ? error.message : defaultErrorMsg);
                setError(true);
              }
            });
        } else {
          throw new Error("No file extension found");
        }
      }
    } catch (error) {
      setErrorMsg(error.message ? error.message : defaultErrorMsg);
      setError(true);
    }
  };

  function onAttachmentChange(e) {
    setError(null);
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 20971520) {
        setErrorMsg("File exceeds 20MB");
        setError(true);
        if (fileInput.current) {
          fileInput.current.value = null;
        }
      } else {
        let blob = window.URL.createObjectURL(e.target.files[0]);
        setFile({
          blob: blob,
          name: e.target.files[0].name,
          filemime: e.target.files[0].type,
        });
      }
    } else if (fileInput.current) {
      setErrorMsg("Invalid file");
      setError(true);
      fileInput.current.value = null;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (file) {
        let filePreBlob = await fetch(file.blob);
        let fileBlob = await filePreBlob.blob();
        let fileData = new File([fileBlob], file.name);
        // set relationship
        let file_field_key = `field_media_file${media.private ? "_1" : ""}`;
        let endp = `${
          media.private ? private_endpoint : public_endpoint
        }${file_field_key}/`;
        let fileResp;
        try {
          fileResp = await fetchAuthenticatedContent(dispatch, endp, "POST", fileData, {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/octet-stream",
            "Content-Disposition": `file; filename="${file.name}"`,
          });
        } catch (err) {
          console.error("COULDNT FETCH BLOB", err.message);
        }
        if (!fileResp) {
          throw new Error();
        } else {
          let entityData = {
            data: {
              id: media.id,
              attributes: { field_filemime: file.filemime, name: file.name },
              type: media.private ? "media--private_document" : "media--document",
            },
          };
          await fetchAuthenticatedContent(
            dispatch,
            media.private ? private_endpoint : public_endpoint,
            "PATCH",
            entityData
          );
          setLoading(false);
          setAuthorDoneReplacing(true);
        }
      }
    } catch (error) {
      setErrorMsg("An error occured while uploading your file");
      setError(true);
      setFile(null);
      setLoading(false);
    }
  }

  const deleteFile = async () => {
    try {
      setAuthorDeleting(true);
      let media_key = media.private ? "private_document" : "document";
      let fileResp = await fetchAuthenticatedContent(
        dispatch,
        `media/${media_key}/${media.id}`,
        "DELETE",
        {
          "Content-Type": "application/vnd.api+json",
        }
      );
      if (!fileResp) {
        throw new Error();
      } else {
        // setError(false);
        setAuthorDeletingMsg("The resource was deleted from the system");
        setAuthorDeleting(false);
      }
    } catch (error) {
      setErrorMsg("There was an error deleting the file");
      setError(true);
      setAuthorDeleting(false);
    }
  };

  const ErrorComponent = () => {
    return (
      <>
        <Link
          to={location?.state?.referrer ? location.state.referrer : "/"}
          className={classes.back}
        >
          <ArrowBackIcon /> Go Back
        </Link>
        <ErrorIcon className={classes.icon} />
        <Typography variant="h3">{errorMsg}</Typography>
      </>
    );
  };

  const LoadingComponent = () => {
    return (
      <>
        <CloudDownloadIcon className={`${classes.icon} loading-pulse`} />
        <Typography variant="h3">Downloading</Typography>
      </>
    );
  };

  const AuthorComponent = () => {
    return (
      <>
        <Link
          to={location?.state?.referrer ? location.state.referrer : "/"}
          className={classes.back}
        >
          <ArrowBackIcon /> Go Back
        </Link>

        {typeof authorDeletingMsg === "string" ? (
          <DeleteIcon className={classes.icon} />
        ) : loading ? (
          <CloudUploadIcon className={`${classes.icon} loading-pulse`} />
        ) : authorDoneReplacing ? (
          <DoneIcon className={classes.icon} />
        ) : (
          <DescriptionOutlinedIcon className={classes.icon} />
        )}
        <Typography variant="h3">
          {authorReplacing && errorMsg.length > 0
            ? errorMsg
            : authorDoneReplacing
            ? "The file was updated for this resource"
            : typeof authorDeletingMsg === "string"
            ? authorDeletingMsg
            : media.title}
        </Typography>
        {typeof authorDeletingMsg !== "string" && !authorReplacing && (
          <div className={classes.buttons}>
            <div>
              <Button
                disabled={authorDeleting !== false}
                variant="contained"
                color="primary"
                onClick={() => {
                  setAuthorDownloading(true);
                  downloadFile();
                }}
              >
                Download
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="default"
                disabled={authorDeleting !== false}
                onClick={() => {
                  setErrorMsg("");
                  setAuthorReplacing(true);
                }}
              >
                Replace
              </Button>
            </div>
            <Button
              disabled={authorDeleting !== false}
              color="secondary"
              className="delete-btn"
              onClick={() => {
                if (typeof window !== "undefined") {
                  if (
                    window.confirm("Are you sure you want to delete this file?") === true
                  ) {
                    deleteFile();
                  }
                }
              }}
            >
              Delete
            </Button>
          </div>
        )}
        {authorReplacing && !authorDoneReplacing && (
          <>
            <form onSubmit={onSubmit}>
              {file === null && (
                <label>
                  <Button
                    type="button"
                    component="div"
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<ComputerIcon />}
                  >
                    Select File
                  </Button>
                  <input
                    className={classes.input}
                    id="media-upload-button"
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.jpg,.jpeg,.png,.gif"
                    type="file"
                    name="media_upload"
                    onChange={onAttachmentChange}
                    ref={(r) => (fileInput.current = r)}
                    style={{ position: "fixed", left: "-9999em" }}
                  />
                </label>
              )}
              {file !== null && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    className={classes.button}
                    disabled={loading}
                    type="submit"
                  >
                    Upload File
                  </Button>
                </>
              )}
              {(authorReplacing || file !== null) && !loading && (
                <Button
                  color="default"
                  className="delete-btn"
                  onClick={() => {
                    setFile(null);
                    setErrorMsg("");
                    setAuthorReplacing(false);
                  }}
                >
                  Cancel
                </Button>
              )}
            </form>
          </>
        )}
      </>
    );
  };

  if (!media && !error)
    return (
      <div className={classes.download}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.download}>
      {userData && media && userData.id === media.author && !authorDownloading ? (
        <AuthorComponent />
      ) : error ? (
        <ErrorComponent />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
}
export default Download;

export const query = graphql`
  query {
    files: allFileRef {
      edges {
        node {
          filemime
          id
        }
      }
    }
  }
`;
